var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-subheader',[_vm._v("Estimation type: "),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","dark":"","x-small":"","outlined":_vm.voteType !== 'sp'},on:{"click":function($event){_vm.voteType = 'sp';
            _vm.$socket.client.emit('vote-type', {
              room: _vm.room,
              voteType: _vm.voteType,
              user: _vm.me,
            });
            _vm.clearVotes(true);}}},[_vm._v("Story Points")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","dark":"","x-small":"","outlined":_vm.voteType !== 'h'},on:{"click":function($event){_vm.voteType = 'h';
            _vm.$socket.client.emit('vote-type', {
              room: _vm.room,
              voteType: _vm.voteType,
              user: _vm.me,
            });
            _vm.clearVotes(true);}}},[_vm._v("time")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","dark":"","x-small":"","outlined":_vm.voteType !== 't'},on:{"click":function($event){_vm.voteType = 't';
            _vm.$socket.client.emit('vote-type', {
              room: _vm.room,
              voteType: _vm.voteType,
              user: _vm.me,
            });
            _vm.clearVotes(true);}}},[_vm._v("t-shirt size")])],1),_c('v-container',{staticClass:"pt-0"},[_c('v-row',_vm._l((_vm.points),function(point,index){return _c('v-col',{key:index,attrs:{"cols":"3","sm":"4","md":"3","lg":"2"}},[_c('sp-card',{class:{ active: _vm.selection === point },attrs:{"vote-type":_vm.voteType,"points":point},on:{"select-sp":_vm.set}})],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-subheader',[_vm._v(" Team Results "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","outlined":"","color":"#a36f95"},on:{"click":function($event){return _vm.clearVotes(false)}}},[_vm._v("clear")]),_c('v-btn',{attrs:{"x-small":"","outlined":"","color":"#a36f95"},on:{"click":_vm.toggleVotes}},[_vm._v(_vm._s(_vm.hiddenVotes ? "show" : "hide"))])],1),_c('v-container',{staticClass:"pt-0 pb-0"},[_c('v-row',[_c('v-col',_vm._l((_vm.allUsers),function(user){return _c('user',{key:user.id,attrs:{"name":user.name,"sp":user.score !== undefined ? user.score : -2,"visible":_vm.hiddenVotes,"vote-type":_vm.voteType}})}),1)],1)],1),_c('v-container',{staticClass:"pt-0"},[_c('small',[_vm._v("Average: "+_vm._s(_vm.average))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }