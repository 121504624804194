
import Vue from "vue";
import { sizeMap } from "../snippe";

export default Vue.extend({
  props: {
    points: {
      type: Number,
      default: 0,
    },
    voteType: {
      type: String,
      default: "sp",
    },
  },
  methods: {
    select(points: number) {
      this.$emit("select-sp", points);
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 350;
    },
    minHeight() {
      if (this.$vuetify.breakpoint.width < 600) {
        return 50;
      } else if (this.$vuetify.breakpoint.width < 960) {
        return 150;
      } else {
        return 175;
      }
    },
    cardTitle() {
      if (this.voteType === "sp" || this.voteType === "h") {
        return this.points > -1 ? this.points : "?";
      }

      return sizeMap.get(this.points);
    },
    spText() {
      if (this.voteType === "t") {
        switch (this.points) {
          case -1:
            return "What is that all about? Why me? 🏫";
          case 1:
            return "Tailor made for dwarfs 🧙🏼‍♂️";
          case 2:
            return "Piece of Cake 🍕";
          case 3:
            return "Gimme More. I'm hungry! ✨";
          case 4:
            return "I can handle that. 🥴";
          case 5:
            return "Can you give me a hand here? ✊";
          case 6:
            return "The pyramids weren't build in one day 😳";
          default:
            return "";
        }
      } else if (this.voteType === "sp") {
        switch (this.points) {
          case -1:
            return "What is that all about? Why me? 🏫";
          case 0:
            return "Consider it DONE 😎";
          case 1:
            return "Takes me 5 min ☑️";
          case 2:
            return "Piece of Cake 🍕";
          case 3:
            return "Gimme More. I'm hungry! ✨";
          case 5:
            return "I can handle that. 🥴";
          case 8:
            return "Can you give me a hand here? ✊";
          case 13:
            return "I will be crying for soo long 😳";
          case 20:
            return "Great Idea. 👀  Care to split?";
          case 40:
            return "Think this can be done? L🤣L";
          case 100:
            return "How about a salary inrease.. 🤯";
          default:
            return "";
        }
      } else {
        switch (this.points) {
          case -1:
            return "What is that all about? Why me? 🏫";
          case 0:
            return "Consider it DONE 😎";
          case 1:
            return "One hour is enough. 🥳";
          case 2:
            return "Two hours pass by like one minute 🚆";
          case 3:
            return "Just three hours. No biggie. 💪";
          case 4:
            return "I can handle that. 🥴";
          case 5:
            return "That's not four, it's FIVE";
          case 6:
            return "Only if you don't call me 🤔";
          case 7:
            return "I will do something productive in the other hour 🕹";
          case 8:
            return "Not a minute more!!11 🤣";
          case 12:
            return "Just one and a half days .. 🤯";
          case 16:
            return "Two days straight. 🙌";
          case 24:
            return "This would be three days if there were no meetings.";
          case 40:
            return "Almost six feet under 💀";
          case 72:
            return "Shut up!";
          default:
            return "";
        }
      }
    },
  },
});
