import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import VuetifyToast from 'vuetify-toast-snackbar'

const socket = io(process.env.VUE_APP_SOCKET_API)

Vue.use(VueSocketIOExt, socket)

Vue.config.productionTip = false

Vue.use(VuetifyToast, {
  $vuetify: vuetify.framework,
  x: 'right', // default
  y: 'bottom', // default
  color: 'rgba(70,50,50,0.4)', // default
  icon: '',
  iconColor: '', // default
  classes: [
    'body-2',
  ],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: false, // default
  closeText: '', // default
  closeIcon: '', // default
  closeColor: '', // default
  slot: [], // default
  shorts: {
    custom: {
      color: 'purple',
    },
  },
  property: '$toast', // default
})

export const VueInstance = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
