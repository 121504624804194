
import Vue from 'vue'
import AppUpdate from '@/components/AppUpdate.vue'

export default Vue.extend({
  ...{
    data () {
      return {
        name: '',
        votes: [],
        room: 'lobby',
      }
    },
    created() {
      if (window.location.hostname === 'estimation-poker.netlify.app') {
        window.location.replace('https://poker.smok.de')
      }
      const username = window.localStorage.getItem('username')
      if (username) {
        this.name = username
      }
      if (!this.name) {
        const rand = (Math.round(Math.random() * 10000)).toString()
        this.name = `Goose-${rand}`
      }
    },
    watch: {
      name(val) {
        window.localStorage.setItem('username', val)
      },
      room(val) {
        if (this.$route.params.room !== val) this.$router.push(val)
      },
      roomFromRoute(val) {
        if (this.room !== val) this.room = val
      },
    },
    methods: {
      copyUrl() {
        const element = document.querySelector('#testing-code')
        if (element) {
          element.setAttribute('type', 'text')
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          element.select()
          try {
            if (document.execCommand('copy')) {
              this.$toast('Copied URL to clipboard. Share it with your team.', { x: 'center', y: 'top' })
            } else {
              this.$toast('Could not copy URL to clipboard. Please do it manually', { x: 'center', y: 'top' })
            }
          } catch (err) {
            this.$toast('Could not copy URL to clipboard. Please do it manually', { x: 'center', y: 'top' })
          }
          /* unselect the range */
          element.setAttribute('type', 'hidden')
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          window.getSelection().removeAllRanges()
        }
      },
    },
    computed: {
      roomFromRoute() {
        return this.$route.params.room
      },
      isXs() {
        return this.$vuetify.breakpoint.width < 400
      },
      documentUrl() {
        return window.location.protocol + '//' + window.location.host + window.location.pathname + '#/' + this.$route.params.room
      },
    },
    sockets: {
      votes (val) {
        this.$data.votes = val
      },
    },
  },
  ...{
    components: {
      AppUpdate,
    },
  },
})
