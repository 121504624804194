import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    votes: [
      { user: 'Maserati', score: 5 },
      { user: 'Audi', score: 2 },
      { user: 'Volvo', score: 8 },
    ],
    myVote: {
      user: null,
      score: -2,
    },
  },
  mutations: {
    setVote(state, payload) {
      state.myVote.user = payload.name
      state.myVote.score = payload.score
    },
  },
  actions: {
    vote(context: any, payload: any) {
      context.commit('setVote', payload)
      // console.log('voted in store')
    },
    fromSocket(context, payload) {
      // console.log('from', context)
      // console.log('socket', payload)
    },
  },
  modules: {
  },
})
