
import Vue from "vue";
import { sizeMap } from "../snippe";

export default Vue.extend({
  props: {
    name: {
      type: String,
      default: "",
    },
    sp: {
      type: Number,
      default: -2,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    voteType: {
      type: String,
      default: "sp",
    },
  },
  computed: {
    result() {
      if (this.sp === -2) return "... undecided";
      if (this.sp === -1 && !this.visible) return "??? What?";
      const labelMap = new Map<string, string>([
        ["sp", "Story Points"],
        ["h", "Hours"],
        ["t", ""],
      ]);
      return labelMap.get(this.voteType);
    },
    spResult() {
      if (this.sp === -2 || (!this.visible && this.sp === -1)) return "";
      if (this.voteType === "t") {
        return sizeMap.get(this.sp);
      }
      return !this.visible ? this.sp : "## ";
    },
  },
});
