
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class AppUpdate extends Vue {
  registration = null as any;
  updateExists = false;
  refreshing = false;
  updateMessage = 'Update';

  created() {
    this.registerEvents()
  }

  refreshApp() {
    this.updateExists = false
    // this makes the new serviceworker take over, above event controllerchange is then being fired
    if (this.registration && this.registration.waiting) {
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }

  registerEvents() {
    // Event fired from registerServiceWorker.ts
    this.$root.$once('update', (msg: string, data: any) => {
      this.registration = data.detail
      this.updateMessage = msg
      this.updateExists = true
    })
    if (navigator.serviceWorker) {
      // Native ServiceWorker Event after Update
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // Prevent multiple refreshes
        if (this.refreshing) {
          return
        }
        this.refreshing = true
        window.location.reload()
      })
    }
  }
}
