import Vue from 'vue'
import Vuetify, { VIcon, VSnackbar } from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {
  components: {
    // the components which Toast.vue used
    VIcon,
    VSnackbar,
  },
})

export default new Vuetify({
  icons: { iconfont: 'md' },
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      dark: {
        primary: '#649A78',
        accent: '#D20079',
        secondary: '#CDAF49',
        success: '#8EB036',
        info: '#696969',
        warning: '#AE9C86',
        error: '#BF7979',
      },
      light: {
        primary: '#3A7B52',
        accent: '#D20079',
        secondary: '#CDAF49',
        success: '#8EB036',
        info: '#696969',
        warning: '#AE9C86',
        error: '#BF7979',
      },
    },
  },
})
