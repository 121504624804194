
import Vue from "vue";
import SpCard from "@/components/SpCard.vue";
import User from "@/components/User.vue";
import { sizeMap } from "../snippe";

export default Vue.extend({
  data: () => {
    return {
      allUsers: [],
      selection: -2,
      hiddenVotes: true,
      voteType: "sp",
    };
  },
  props: {
    me: {
      type: String,
      default: "",
    },
    room: {
      type: String,
      default: "",
    },
  },
  created() {
    if (!this.room) {
      this.$router.push("lobby");
    } else {
      this.$socket.client.emit("join", { name: this.me, room: this.room });
    }
  },
  watch: {
    room() {
      this.join();
    },
    me(newN, oldN) {
      this.$socket.client.emit("rename", {
        room: this.room,
        oldName: oldN,
        newName: newN,
      });
    },
  },
  methods: {
    toggleVotes() {
      this.hiddenVotes = !this.hiddenVotes;
      this.$socket.client.emit(this.hiddenVotes ? "hideVotes" : "showVotes", {
        room: this.room,
        name: this.me,
      });
    },
    clearVotes(silent: boolean) {
      this.$socket.client.emit("clear", {
        room: this.room,
        name: this.me,
        silent: silent,
      });
    },
    join() {
      this.$socket.client.emit("join", {
        name: this.me,
        room: this.room,
        vote: this.selection,
      });
    },
    set(sp: any) {
      if (!this.hiddenVotes) {
        this.$emit("votes-locked");
        this.$toast("Votes are locked, please wait until the next round.");
        return;
      }
      if (this.selection === sp) {
        this.selection = -2;
      } else {
        this.selection = sp;
      }
      this.$socket.client.emit("vote", {
        name: this.me,
        score: this.selection,
        room: this.room,
      });
    },
  },
  computed: {
    points(): number[] {
      switch (this.voteType) {
        case "t": // xs  s  m  l  xl xxl
          return [-1, 1, 2, 3, 4, 5, 6];
        case "sp":
          return [-1, 0, 1, 2, 3, 5, 8, 13, 20, 40, 100];
        default:
          return [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 12, 16, 24, 40, 72];
      }
    },
    average(): number | string {
      if (this.allUsers.length < 1) return 0;

      let sum = 0;
      let participants = 0;

      for (const user of this.allUsers) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (user.score >= 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          sum += user.score;
          participants++;
        }
      }

      const avg = Math.round(sum / participants);
      if (this.voteType === "t") {
        return avg > 0 && this.hiddenVotes === false
          ? sizeMap.get(avg) || "0"
          : "0";
      }
      return avg > 0 && this.hiddenVotes === false ? avg : 0;
    },
  },
  components: {
    SpCard,
    User,
  },
  sockets: {
    connect() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.join();
    },
    users(val) {
      this.$data.allUsers = val;
    },
    clear({ name, silent }) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.selection = -2;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.hiddenVotes = true;
      if (!silent) {
        this.$toast(`Estimates cleared by ${name}`, { timeout: 2000 });
      }
    },
    hidden({ val, name }) {
      if (val === false) {
        this.$toast(`Estimates shown by ${name}`, { timeout: 2000 });
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.hiddenVotes = val;
    },
    showVotes() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.hiddenVotes = false;
    },
    voteType({ voteType, user }) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.voteType = voteType;
      const voteTypeMap = new Map<string, string>([
        ["sp", "story points"],
        ["h", "time"],
        ["t", "t-shirt sizes"],
      ]);
      const friendlyName = voteTypeMap.get(voteType) || "";

      this.$toast(`${user} changed the vote type to ${friendlyName}`, {
        timeout: 2000,
      });
    },
  },
});
